import { apiSlice } from './apiSlice';
import * as Globals from '../application/common';
import { toast } from 'react-toastify';

export interface PlanType {
  id?: number;
  clientShortCode: string;
  jobID: string;
  jobPlanID: number | null;
  planAssetValue: number; //planSize
  planMandateSize: number; //MandateSize
  planShortCode: string;
}

export interface PlanListType {
  clientShortCode: string;
  planAssetValue: number;
  planName: string;
  planShortCode: string;
}

export interface PlanJiraType {
  planMandateSize: number;
  planName: string;
  planShortCode: string;
}

export const extendedApiPlansSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlansByClientShortCode: builder.query({
      transformResponse: (baseQueryReturnValue?: PlanListType[]): PlanListType[] => baseQueryReturnValue ?? [],
      transformErrorResponse: (response) => {
        toast.error(`Error getting plans dropdown list. Error: ${response.status}`);
        return response;
      },
      query: (shortId) => `${Globals.API.endpoints.Plans}/${shortId}`,
      keepUnusedDataFor: 600,
    }),
  }),
});

export const { useGetPlansByClientShortCodeQuery } = extendedApiPlansSlice;
